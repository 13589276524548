.floating-action-button {
  bottom: 0;
  right: 0;
  margin: 48px;
  position: fixed;
  z-index: 1;

  &--left {
    left: 0;
  }

  &--right {
    right: 0
  }
}

@media (max-width: 480px) {
  .floating-action-button {
    margin: 24px;
  }
}
