.footer-stat {
  margin: 0 6px;

  &:first-child {
    margin-left: 12px;
  }

  &__icon {
    cursor: default;
    float: left;
    font-size: 20px;
  }

  &__text {
    cursor: default;
    float: left;
    margin-left: 2px;
  }
}
