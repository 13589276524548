.mdc-drawer-app-content {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;

  .page {
    &__main {
      background-color: #f5f5f5;
      width: 100%;
      min-height: 100%;
      overflow: auto;
    }
  }
}

.drawer-toggle-fab {
  background: #ffffff;
  color: #333333;

  &--drawer-open {
    left: 256px;
  }
}

@media (max-width: 480px) {
  .drawer-toggle-fab {
    z-index: 7;

    &--drawer-open {
      left: initial;
      right: 0;
    }
  }
}
