.dialog {
  &__title-bar {
    &__title {
      float: left;
      margin: 10px 16px;
    }

    &__close-button {
      float: right;
    }
  }

  &__content {
    padding: 8px 16px;
  }
}