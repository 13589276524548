.pod-form {
  &__line {
    width: 100%;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 8px;
    }
  }

  &__float-right {
    float: right;
  }
}
