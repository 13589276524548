.mdc-tab {
  &--stacked &__content .madonna-icon {
    margin-top: 12px;
  }
}

.tab-navigator {
  &__component-area {
    position: absolute;
    top: 72px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }
}
