// @material
@import '~@material/button/dist/mdc.button.css';
@import '~@material/card/dist/mdc.card.css';
@import '~@material/checkbox/dist/mdc.checkbox.css';
@import '~@material/data-table/dist/mdc.data-table.css';
@import '~@material/dialog/dist/mdc.dialog.css';
@import '~@material/drawer/dist/mdc.drawer.css';
@import '~@material/floating-label/dist/mdc.floating-label.css';
@import '~@material/form-field/dist/mdc.form-field.css';
@import '~@material/icon-button/dist/mdc.icon-button.css';
@import '~@material/layout-grid/dist/mdc.layout-grid.css';
@import '~@material/line-ripple/dist/mdc.line-ripple.css';
@import '~@material/notched-outline/dist/mdc.notched-outline.css';
@import '~@material/ripple/dist/mdc.ripple.css';
@import '~@material/textfield/dist/mdc.textfield.css';
@import '~@material/typography/dist/mdc.typography.css';

// @rmwc
@import '~@rmwc/circular-progress/circular-progress.css';
@import '~@rmwc/data-table/data-table.css';
@import '~@rmwc/icon/icon.css';
@import '~@rmwc/list/collapsible-list.css';

// invotra-mdc/styles
@import '~@invotra/invotra-mdc/src/fonts/icomoon/style.css';
@import '~@invotra/invotra-mdc/styles/base.scss';
@import '~@invotra/invotra-mdc/styles/decorators/fade.scss';
@import '~@invotra/invotra-mdc/styles/decorators/grow.scss';
@import '~@invotra/invotra-mdc/styles/includes';

// invotra-mdc/styles/widgets
@import '~@invotra/invotra-mdc/styles/widgets/apps.scss';
@import '~@invotra/invotra-mdc/styles/widgets/button.scss';
@import '~@invotra/invotra-mdc/styles/widgets/drawer.scss';
@import '~@invotra/invotra-mdc/styles/widgets/fab.scss';
@import '~@invotra/invotra-mdc/styles/widgets/field-legend.scss';
@import '~@invotra/invotra-mdc/styles/widgets/icon.scss';
@import '~@invotra/invotra-mdc/styles/widgets/icon-button.scss';
@import '~@invotra/invotra-mdc/styles/widgets/list.scss';
@import '~@invotra/invotra-mdc/styles/widgets/loading.scss';
@import '~@invotra/invotra-mdc/styles/widgets/menu.scss';
@import '~@invotra/invotra-mdc/styles/widgets/root-search.scss';
@import '~@invotra/invotra-mdc/styles/widgets/select.scss';
@import '~@invotra/invotra-mdc/styles/widgets/switch.scss';
@import '~@invotra/invotra-mdc/styles/widgets/table.scss';
@import '~@invotra/invotra-mdc/styles/widgets/tabs.scss';
@import '~@invotra/invotra-mdc/styles/widgets/text-field.scss';
@import '~@invotra/invotra-mdc/styles/widgets/tooltip.scss';
@import '~@invotra/invotra-mdc/styles/widgets/top-app-bar.scss';
@import '~@invotra/invotra-mdc/styles/widgets/workbar.scss';

// app styles
@import './components/common/AddNew/style.scss';
@import './components/common/Dialog/style.scss';
@import './components/common/Drawer/style.scss';
@import './components/common/DrawerLink/style.scss';
@import './components/common/Fab/style.scss';
@import './components/common/PaddedArea/style.scss';
@import './components/common/Page/style.scss';
@import './components/common/TabNavigator/style.scss';
@import './components/common/WorkBar/style.scss';
@import './components/pages/CompanyDetails/components/tabs/Pods/components/Pod/style.scss';
@import './components/pages/CompanyDetails/components/tabs/Pods/components/Pod/components/FooterStat/style.scss';
@import './components/pages/CompanyDetails/components/tabs/Pods/components/Pod/components/PrivacySetting/style.scss';
@import './components/pages/CompanyDetails/components/tabs/Pods/components/PodForm/style.scss';
@import './components/pages/CompanyDetails/components/tabs/Pods/style.scss';

body {
  margin: 0;
  background-color: #f5f5f5;
}
