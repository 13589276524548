.invotra-pods__drawer {
  float: left;
  top: 56px;
  bottom: 0;
  position: fixed;

  &__footer {
    bottom: 0;
    margin-bottom: 8px;
    position: absolute;
    width: 100%;
  }

  .madonna-field-legend {
    button.mdc-list-item {
      margin: 0;
      border-radius: 0;
      padding: 0 16px;
    }
  }
}

.madonna-drawer-container {
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}

@media (max-width: 480px) {
  .invotra-pods__drawer {
    width: 100%;

    .madonna-drawer {
      width: 100%;
    }
  }
}
